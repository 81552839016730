.not-valid {
  padding: 16px;
  font-size: medium;
  max-width: 800px;
}

.main {
  margin-left: 0;
  max-width: 600px;
  padding-bottom: 50px;
}

.main-spinner {
  align-content: center;
}

.button-spinner {
  display: flex;
  padding:0;
  justify-content: space-between;
  align-items: center;
  column-gap: 4px;
}

.main-head {
  background-color: #005cbf;
  color: #7abaff;
  font-size: 32px;
  font-weight: bold;
  padding-left: 0.5em;
}

.user-head {
  background-color: #7abaff;
  color: #343a40;
  padding-left: 1em;
}

.user-foot {
  background-color: #7abaff;
  color: #343a40;
  padding-left: 1em;
  position: fixed;
  width: 100%;
  bottom:0;
}

.date-select {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 1em;
}

.feature-select {
  padding-bottom: 1em;
  margin-left: 0;
  max-width: 400px;
}

.search-form {
  max-width: 450px;
  padding: 4px;
}

.reset-button {
  padding-top: 4px;
}

.radio {
  display: flex;
  align-items: center;
}

.request {
  margin-bottom: 16px;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin-left: 16px;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.car_item {
  margin-bottom: 16px;
  margin-left: 0;
  padding-left: 0;
}

.car-list {
  margin-bottom: 16px;
  margin-left: 0;
  padding-left: 0;
}

.car-form {
  justify-content: flex-start;
  margin-left: 0;
  max-width: 300px;
}

.add-new-car {
  background-color: darkred;
  margin-bottom: 16px;
}

.calendar-message {
  margin-bottom: 8px;
}

.my-car-message {
  border-bottom: 2px solid #005cbf;
  margin-bottom: 16px;
}

table.calendar-table {
  padding: 0;
  /*max-width: 300px;*/
}

.calendar-table-body {
  width: 100%;
  padding: 0;
  overflow-y: auto;
  max-height: 70vh;
  /*outline: 1px solid black;*/
}

tr.calendar-table {
  border-bottom: 1px solid black;
  line-height: 1em;
}

td.calendar-table {
  width: 25px;
}

th.calendar-table {
  position: sticky;
  top: 0;
  vertical-align: bottom;
  width: 25px;
  height: 80px;
  background-color: white;
  box-shadow: 0px 1px black;
}

th.calendar-table-day {
  background-color: #e0a800;
  position: sticky;
  top: 80px;
  box-shadow: 0px 1px black;
  text-align: center;
}

th.calendar-table-row-label {
  position: sticky;
  top: 0;
  vertical-align: bottom;
  width: 25px;
  height: 80px;
  background-color: white;
  box-shadow: 0px 1px black;
}

.calendar-table-row-label {
  z-index: -1;
  text-align: right;
  width: 60px;
  max-width: 60px;
}

.calendar-table-start, .calendar-table-block, .calendar-table-end, .calendar-table-blip {
  background-color: darkred;
  width: 10px;
  max-width: 20px;
  border: 2px darkred;
  border-style: solid;
  margin-bottom: -1px;
  margin-top: -1px;
  padding-left: 5px;
  padding-right: 5px;
  text-indent: -1000px;
}

.calendar-table-start {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.calendar-table-end {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.calendar-table-blip {
  border-radius: 10px;
}

.calendar-table-label {
  margin-bottom: 30px;
  margin-right: 0;
  padding: 0;
  text-align: left;
  transform: rotate(-90deg);
  overflow: hidden;
}